<template>
    <h2>A & M AMUSEMENTS: CLIENT PROJECT</h2>
    <div class="content">
        <h1>BRIEF</h1><br>
        <p>For your Final in this course you will be working in groups on a real-world client project. This project will be to develop a website for a client using Wordpress. 
            The client will be visiting the class to give an overview of their business and what they are looking for. We will use the next 4 weeks for your group to complete this project.</p><br>
        <h1>ABOUT THE CLIENT</h1><br>
        <p>The client is a provider of family attraction equipment - inflatables, costumes, water slides, and concessions. The client wanted a fun, easy to navigate website that attracted all age groups.</p><br>
        <h1>THOUGHTS</h1><br>
        <p>For this website, our group decided to research competitor's websites in hopes to better understand the layout and design of them. We found that most inflatable websites are colorful and family friendly.
            Because of this, we decided follow the logo of the site and make the main color scheme of the website blue. We also decided to display the company's featured items at the home page in order
            to show interested renters what the most popular items were.</p><br>
        <h1>ROLE</h1><br>
        <p>My role in our group of three was to create the nav, carousel slider, rentals page, product page, and the contact us page.</p><br>
        <h1>WHAT DID I LEARN?</h1><br>
        <p>While making the website, I learned how to use WordPress extensions and themes. After we presented the website to the client, the client hired me and another group member to help maintain the website.
            While being contracted, I learned how to add custom CSS and JS into WordPress. Since we weren't allowed to contact the client while creating the website, it was valuable to be able to speak to the client directly
            about what they want to be changed to the site, which allowed me to grow my client communication skills.</p><br>
        <!--<h1 class="center">COMPETITOR SITES</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/am1.png"></div>
            <div><img src="../assets/am2.png"></div>
        </div><br>-->
        <h1 class="center">FINAL SITE</h1><br>
        <div class="imagecontainer wrap">
            <div><img src="../assets/am3.png"></div>
            <div><img src="../assets/am4.png"></div>
            <div><img src="../assets/am5.png"></div>
            <div><img src="../assets/am6.png"></div>
        </div>
    </div><br><br><br>
</template>
<style>
.item > img {
    width:100%;
    border-radius:8px;
    display: block;
}
</style>